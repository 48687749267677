import styled from "styled-components";
import { Rem } from "../../../commons/Theme";

export const StyledLateralTextContainer = styled.div`
  padding: ${Rem(10)};
  padding-right: ${Rem(30)};
  > a {
    text-decoration: none;
  }
`;
