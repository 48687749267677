import React from "react";
import { StyledParagraph, StyledSpacer, StyledTitle } from "../../globalstyle";
import { StyledLateralTextContainer } from "./style";
import Link from "../../Link/Link";
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";

/**
 *
 * @param {{to: string; title: string; paragraph: string}} Props
 */

const SimpleMode = ({ to, title, paragraph }) => {
  const { i18nLink } = useContext(DataContext);

  return (
    <StyledLateralTextContainer>
      <Link to={to ? i18nLink(to) : "#"}>
        <StyledTitle
          mini
          color="white"
          font="sans"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <StyledSpacer size={1} />
        <StyledParagraph
          mini
          color="grayDark"
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      </Link>
    </StyledLateralTextContainer>
  );
};

export default SimpleMode;
